import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page, PagePackage } from '../resume-parser/models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { PackageService } from './services/package.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { display_color } from './models/package.model';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ResultPackage } from '../resume-parser/models/resume.model';
import { DashboardService } from '../dashboard/services/dashboard.service';
import { ListCompany, User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PackageComponent implements OnInit {
  popup = false
  listSubscriptionPlan: any[] = []
  listSubscriptionPlanItems: any[] = []
  public pageSubscriptionPlan = new PagePackage({});
  @ViewChild(DatatableComponent) tableRowSubscriptionPlan: DatatableComponent;
  detailPackage: any
  //package
  listPackage: any[] = []
  listPackageItems: any[] = []
  public pagePackage = new PagePackage({});
  @ViewChild(DatatableComponent) tableRowPackage: DatatableComponent;
  //Cost
  listSubscriptionPlanCost: any[] = []
  listSubscriptionPlanCostItems: any[] = []
  public pageSubscriptionPlanCost = new PagePackage({});
  @ViewChild(DatatableComponent) tableRowSubscriptionPlanCost: DatatableComponent;
  ColumnMode = ColumnMode;
  CreateSubscriptionPlanForm!: FormGroup;
  CreatePackageForm!: FormGroup;
  CreateSubscriptionPlanCostForm!: FormGroup;
  public submitted = false;
  public display_color=display_color;
  editSubscriptionPlanBoolean= false;
  editPackageBoolean= false;
  editSubscriptionPlanCostBoolean= false;
  public HTMLItems:string[] = [
    'Giao diện edit kết quả chuyển đổi',
    'Số luồng song song: 1',
    'Thời gian lưu trữ resume trên server: 7 ngày',
    'Thời gian lưu trữ resume trên server: 30 ngày',

  ]
  public recurrence_unit_items = [{
    label: 'Tháng', value: "6"
  },
  {label: 'Năm', value: "7"},
  {label: 'Ngày', value: "4"},

  ]
  html_content: string[] = []
  title: string;
  description: string;
  is_display: boolean;
  is_free_trial: boolean;
  package: number;
  id: any;
  order: number;
  //cost
  name: string;
  descriptionCost: string;
  cost: number = 9999;
  recurrence_period: number = 1;
  recurrence_unit: string = "6";
  is_default: boolean;
  subscription_plan: number;
  idCost: any;
  //Add plan cost for user
  company_id: number;
  user_id: number;
  public listCompany: ListCompany[] = []
  public users: User[] = []
  public pageCompany = new Page({});
  public pageTrial = new Page({});
  @ViewChild(DatatableComponent) tableCompany: DatatableComponent;
  @ViewChild(DatatableComponent) tableTrial: DatatableComponent;
  widthColumn: number = 0;
  
  constructor(
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    private packageService: PackageService,
    private _toastrService: ToastrService,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.CreateSubscriptionPlanForm = new FormGroup({
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
      // html_content: new FormControl([], Validators.required),
      is_display: new FormControl(false),
      is_free_trial: new FormControl(false),
      package: new FormControl(null),
      id: new FormControl('')
    });
    this.CreatePackageForm = new FormGroup({
      name: new FormControl('', Validators.required),
      parse_thread_limit: new FormControl(null, Validators.required),
      matching_thread_limit: new FormControl(null, Validators.required),
      parse_limited: new FormControl(null, Validators.required),
      matching_limited: new FormControl(null, Validators.required),
      max_user: new FormControl(null, Validators.required),
      id: new FormControl('')
    });
    this.CreateSubscriptionPlanCostForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      cost: new FormControl(null, Validators.required),
      recurrence_period: new FormControl(null, Validators.required),
      recurrence_unit: new FormControl(null, Validators.required),
      is_default: new FormControl(false, Validators.required),
      subscription_plan: new FormControl(null, Validators.required),
      id: new FormControl('')
    });
   }
   changePackage(event: any) {
    this.listPackage.find((r) => r == event);
  }
  ListCompany(){
    this.authService.list_company().subscribe(
      (res)=>{
        this.listCompany = res.results;
        const currentPageNumber = this.pageCompany.pageNumber;
        const currentPageSize = this.pageCompany.size;
        this.pageCompany = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  setPageCompany(pageInfo) {
    this.pageCompany.pageNumber = pageInfo.offset;
    this.ListCompany()
  }
  listUser(){
    this.authService.listUsers('?is_free_trial=true').subscribe(
      (res)=>{
        this.users = res.results;
        const currentPageNumber = this.pageTrial.pageNumber;
        const currentPageSize = this.pageTrial.size;
        this.pageTrial = new Page({
          size: currentPageSize,
          totalElements: res.results.length,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  setPageTrial(pageInfo) {
    this.pageTrial.pageNumber = pageInfo.offset;
    this.listUser()
  }
  general(){

  }
  packageConfig(){

  }
  pricingConfig(){

  }
  getListSubscriptionPlan(all=false){
    if(all){
      this.packageService.listSubscriptionPlan(null, null).subscribe(
        (res)=>{
          this.listSubscriptionPlanItems = res;
        },
        (err)=>{
          console.log("err",err)
        }
      )
    }else{
      this.packageService.listSubscriptionPlan(this.pageSubscriptionPlan.pageNumber, this.pageSubscriptionPlan.size).subscribe(
        (res)=>{
          this.listSubscriptionPlan = res.results;
          const currentPageNumber = this.pageSubscriptionPlan.pageNumber;
          const currentPageSize = this.pageSubscriptionPlan.size;
          this.pageSubscriptionPlan = new Page({
            size: currentPageSize,
            totalElements: res.count,
            pageNumber: currentPageNumber,
          });
        },
        (err)=>{
          console.log("err",err)
        }
      )
    }
  }
  setPageSubscriptionPlan(pageInfo) {
    this.pageSubscriptionPlan.pageNumber = pageInfo.offset;
    this.getListSubscriptionPlan(false)
  }
  modalOpenCreateSubscriptionPlan(modalSubscriptionPlan:any, info: any = null){
    if(info){
      this.editSubscriptionPlanBoolean = true
      this.title = info.title;
      this.description = info.description;
      this.is_display = info.is_display;
      this.is_free_trial = info.is_free_trial;
      this.package = info.package;
      this.html_content = info.html_content;
      this.id = info.id
      this.order = info.order

    }else{
      this.getListPackage(true)
      this.editSubscriptionPlanBoolean = false;
      this.title = '';
      this.description = '';
      this.is_display = false;
      this.is_free_trial = false;
      this.package = null;
      this.html_content = [];
      this.id = null
      this.order = null

    }
    this.modalService.open(modalSubscriptionPlan, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  createSubscriptionPlan(){
    this.submitted = true;
    let formData: any = {}
    formData["title"] = this.title
    formData["description"] = this.description
    formData["html_content"] = this.html_content
    formData["is_display"] = this.is_display
    formData["is_free_trial"] = this.is_free_trial
    formData["package"] = this.package
    formData["order"] = this.order

    let id = this.id
    if(id!=null && id!=undefined){
      this.packageService.updateSubscriptionPlan(id, formData).subscribe(
        (res)=>{
          
          this.modalService.dismissAll();
          this.getListSubscriptionPlan();
        },
        (err)=>{
          this._toastrService.error(err.error.message);
          
          this.modalService.dismissAll();
        }
      )
    }else{
      this.packageService.createSubscriptionPlan(formData).subscribe(
        (res)=>{
          
          this.modalService.dismissAll();
          this.getListSubscriptionPlan();
        },
        (err)=>{
          this._toastrService.error(err.error.message);
          
          this.modalService.dismissAll();
        }
      )
    }
    
  }
  get f_SubscriptionPlan(){
    return this.CreateSubscriptionPlanForm.controls;
  }
  deleteSubscriptionPlan(id: any) {
    Swal.fire({
      title: this._translateService.instant("GENERAL.MODAL.DELETE_A"),
      text: this._translateService.instant("GENERAL.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("GENERAL.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("GENERAL.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.packageService.deleteSubscriptionPlan(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("GENERAL.TOAST.DELETE_A_SUC"),
              this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListSubscriptionPlan();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("GENERAL.TOAST.ERROR"),
              this._translateService.instant("GENERAL.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  closeDetailSubscriptionPlan(){
    this.detailPackage = null
  }
  detailSubscriptionPlan(id:any){
    this.packageService.detailSubscriptionPlan(id).subscribe(
      (res)=>{
        this.detailPackage = res
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  getListPackage(all=false){
    if(all){
      this.packageService.listPackage(null, null).subscribe(
        (res)=>{
          this.listPackageItems = res;
        },
        (err)=>{
          console.log("err",err)
        }
      )
    }else{
      this.packageService.listPackage(this.pagePackage.pageNumber, this.pagePackage.size).subscribe(
        (res)=>{
          this.listPackage = res.results;
          const currentPageNumber = this.pagePackage.pageNumber;
          const currentPageSize = this.pagePackage.size;
          this.pagePackage = new Page({
            size: currentPageSize,
            totalElements: res.count,
            pageNumber: currentPageNumber,
          });

        },
        (err)=>{
          console.log("err",err)
        }
      )
    }
    
  }
  setPagePackage(pageInfo) {
    this.pagePackage.pageNumber = pageInfo.offset;
    this.getListPackage(false)
  }
  modalOpenCreatePackage(modalPackage:any, info: any = null){
    if(info){
      this.editPackageBoolean = true
      this.CreatePackageForm.get("name").setValue(info.name);
      this.CreatePackageForm.get("parse_thread_limit").setValue(info.parse_thread_limit);
      this.CreatePackageForm.get("matching_thread_limit").setValue(info.matching_thread_limit);
      this.CreatePackageForm.get("parse_limited").setValue(info.parse_limited);
      this.CreatePackageForm.get("matching_limited").setValue(info.matching_limited);
      this.CreatePackageForm.get("max_user").setValue(info.max_user);
      this.CreatePackageForm.get("id").setValue(info.id);

    }else{
      this.editPackageBoolean = false;
      this.CreatePackageForm.patchValue({
        name: null,
        parse_thread_limit: null,
        matching_thread_limit: null,
        parse_limited: null,
        matching_limited: null,
        max_user: null,
        id:null
      })
    }
    this.modalService.open(modalPackage, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  createPackage(){
    this.submitted = true;
    if(this.CreatePackageForm.invalid){
      return;
    }
    let formData: any = {}
    formData["name"] = this.CreatePackageForm.value.name
    formData["parse_thread_limit"] = this.CreatePackageForm.value.parse_thread_limit
    formData["matching_thread_limit"] = this.CreatePackageForm.value.matching_thread_limit
    formData["parse_limited"] = this.CreatePackageForm.value.parse_limited
    formData["matching_limited"] = this.CreatePackageForm.value.matching_limited
    formData["max_user"] = this.CreatePackageForm.value.max_user
    let id = this.CreatePackageForm.get("id").value
    if(id!=null && id!=undefined){
      this.packageService.updatePackage(id, formData).subscribe(
        (res)=>{
          this.CreatePackageForm.reset();
          this.modalService.dismissAll();
          this.getListPackage();
          this.getListSubscriptionPlan()
        },
        (err)=>{
          this._toastrService.error(err.error.message);
          this.CreatePackageForm.reset();
          this.modalService.dismissAll();
        }
      )
    }else{
      this.packageService.createPackage(formData).subscribe(
        (res)=>{
          this.CreatePackageForm.reset();
          this.modalService.dismissAll();
          this.getListPackage();
          this.getListSubscriptionPlan()
        },
        (err)=>{
          console.log("err",err)
          this._toastrService.error(err.error.message);
          this.CreatePackageForm.reset();
          this.modalService.dismissAll();
        }
      )
    }
    
  }
  get f_Package(){
    return this.CreatePackageForm.controls;
  }
  deletePackage(id: any) {
    Swal.fire({
      title: this._translateService.instant("PACKAGES_CONFIG.MODAL.DELETE_A"),
      text: this._translateService.instant("PACKAGES_CONFIG.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("PACKAGES_CONFIG.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("PACKAGES_CONFIG.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.packageService.deletePackage(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("PACKAGES_CONFIG.TOAST.DELETE_A_SUC"),
              this._translateService.instant("PACKAGES_CONFIG.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListPackage();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("PACKAGES_CONFIG.TOAST.ERROR"),
              this._translateService.instant("PACKAGES_CONFIG.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  getListSubscriptionPlanCost(all=false){
    if(all){
      this.packageService.listSubscriptionPlanCost(null, null).subscribe(
        (res)=>{
          this.listSubscriptionPlanCostItems = res;
        },
        (err)=>{
          console.log("err",err)
        }
      )
    }else{
      this.packageService.listSubscriptionPlanCost(this.pageSubscriptionPlanCost.pageNumber,this.pageSubscriptionPlanCost.size).subscribe(
        (res)=>{
          this.listSubscriptionPlanCost = res.results;
          this.listSubscriptionPlanCost = res.results.map((result)=>({
            ...result,
            cost: result.cost.split('.')[0]
          }))
          const currentPageNumber = this.pageSubscriptionPlanCost.pageNumber;
          const currentPageSize = this.pageSubscriptionPlanCost.size;
          this.pageSubscriptionPlanCost = new Page({
            size: currentPageSize,
            totalElements: res.count,
            pageNumber: currentPageNumber,
          });
        },
        (err)=>{
          console.log("err",err)
        }
      )
    }
  }
  convertCost(cost: any){
    return new Intl.NumberFormat(
          'vi-IN', 
          { style: "currency", currency: "VND" }
      ).format(cost)
  }
  setPageSubscriptionPlanCost(pageInfo) {
    this.pageSubscriptionPlanCost.pageNumber = pageInfo.offset;
    this.getListSubscriptionPlanCost(false)
  }
  modalOpenCreateSubscriptionPlanCost(modalSubscriptionPlanCost:any, info: any = null){
    if(info){
      this.editSubscriptionPlanCostBoolean = true
      this.name = info.name;
      this.descriptionCost = info.descriptionCost;
      this.cost = info.cost;
      this.recurrence_period = info.recurrence_period;
      this.recurrence_unit = info.recurrence_unit;
      this.is_default = info.is_default;
      this.subscription_plan = info.subscription_plan;
      this.idCost = info.idCost

    }else{
      this.getListSubscriptionPlan(true)
      this.editSubscriptionPlanCostBoolean = false;
      this.name = '';
      this.descriptionCost = '';
      this.cost = 9999;
      this.recurrence_period = 1;
      this.recurrence_unit = "6";
      this.is_default = false;
      this.subscription_plan = null;
      this.idCost = null
    }
    this.modalService.open(modalSubscriptionPlanCost, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  modalOpenAddPlanCostForUser(modalAddPlanCost:any, info: any = null,addCompany: boolean){
    this.packageService.listSubscriptionPlanCost(this.pageSubscriptionPlanCost.pageNumber,this.pageSubscriptionPlanCost.size=100).subscribe(
      (res)=>{
        this.listSubscriptionPlanCost = res.results;
        this.listSubscriptionPlanCost = res.results.map((result)=>({
          ...result,
          cost: result.cost.split('.')[0]
        }))
        const currentPageNumber = this.pageSubscriptionPlanCost.pageNumber;
        const currentPageSize = this.pageSubscriptionPlanCost.size;
        this.pageSubscriptionPlanCost = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log("err",err)
      }
    )
    if(addCompany){
      this.company_id = info.company_id;
    }else{
      this.user_id = info.user_id
    }
    this.getListSubscriptionPlanCost(true)
    this.modalService.open(modalAddPlanCost, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  addPlanCostForUser(){
    this.submitted = true;
    if(this.company_id){
      let formData: any = {}
      formData["cost_id"] = this.idCost
      formData["company_id"] = this.company_id
      this.packageService.addPlanCostForCompany(formData).subscribe((res)=>{
        this.modalService.dismissAll();
        this.idCost = null
        this.company_id = null
        this.ListCompany()
      },
      (err)=>{
        this._toastrService.error(err.error.message);
          
        this.modalService.dismissAll();
      }
      )
    }else{
      let formData: any = {}
      formData["cost_id"] = this.idCost
      formData["user_id"] = this.user_id
      this.packageService.addPlanCostForFreeTrial(formData).subscribe((res)=>{
        this.modalService.dismissAll();
        this.idCost = null
        this.listUser()
      },
      (err)=>{
        this._toastrService.error(err.error.message);
          
        this.modalService.dismissAll();
      }
      )
    }
    
  }
  createSubscriptionPlanCost(){
    this.submitted = true;
    let formData: any = {}
    formData["name"] = this.name
    formData["description"] = this.descriptionCost
    formData["cost"] = this.cost
    formData["recurrence_period"] = this.recurrence_period
    formData["recurrence_unit"] = this.recurrence_unit
    formData["is_default"] = this.is_default
    formData["subscription_plan"] = this.subscription_plan
    let id = this.idCost
    console.log("id",id)
    if(id!=null && id!=undefined){
      this.packageService.updateSubscriptionPlanCost(id, formData).subscribe(
        (res)=>{
          
          this.modalService.dismissAll();
          this.getListSubscriptionPlanCost();
          this.getListSubscriptionPlan();

        },
        (err)=>{
          this._toastrService.error(err.error.message);
          
          this.modalService.dismissAll();
        }
      )
    }else{
      this.packageService.createSubscriptionPlanCost(formData).subscribe(
        (res)=>{
          
          this.modalService.dismissAll();
          this.getListSubscriptionPlanCost();
          this.getListSubscriptionPlan();

        },
        (err)=>{
          this._toastrService.error(err.error.message);
          
          this.modalService.dismissAll();
        }
      )
    }
    
  }
  get f_SubscriptionPlanCost(){
    return this.CreateSubscriptionPlanCostForm.controls;
  }
  deleteSubscriptionPlanCost(id: any) {
    Swal.fire({
      title: this._translateService.instant("PRICING_CONFIG.MODAL.DELETE_A"),
      text: this._translateService.instant("PRICING_CONFIG.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("PRICING_CONFIG.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("PRICING_CONFIG.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.packageService.deleteSubscriptionPlanCost(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("PRICING_CONFIG.TOAST.DELETE_A_SUC"),
              this._translateService.instant("PRICING_CONFIG.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListSubscriptionPlanCost();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("PRICING_CONFIG.TOAST.ERROR"),
              this._translateService.instant("PRICING_CONFIG.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  ngOnInit(): void {
    this.getListSubscriptionPlan(false);
    this.getListPackage(false);
    this.getListSubscriptionPlanCost(false);
    this.getListSubscriptionPlan(true);
    this.getListPackage(true);
    this.getListSubscriptionPlanCost(true);
    this.ListCompany();
    this.listUser();
  }

}
