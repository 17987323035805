<div class="content-wrapper container-xxxl p-0 card">
  <div class="content-body card-body" id="ngx-datatable-row-details">
    <form (ngSubmit)="(SearchResume.form.valid)" #SearchResume="ngForm">
      <div class="row mb-2">
        <div class="col-md-2">
          <ng-select (keydown.enter)="onSubmit()" tabindex="1" bindLabel="name" bindValue="value"
            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_STATUS'|translate" name="selectedStatus"
            [(ngModel)]="selectedStatus" id="selectedStatus" [multiple]="true">
            <ng-option *ngFor="let _status of status" [value]="_status.value">
              <span *ngIf="_status.name=='Approved'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED'"></span>
              <span *ngIf="_status.name=='Duplicated'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DUPLICATED'"></span>
              <span *ngIf="_status.name=='Done'" [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DONE'"></span>
              <span *ngIf="_status.name=='Failure'" [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.FAILURE'"></span>
              <span *ngIf="_status.name=='Processing'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.PROCESSING'"></span>
              <span *ngIf="_status.name=='Wait to process'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.WAIT_TO_PROCESS'"></span>
              <span *ngIf="_status.name=='Suspended'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.SUSPENDED'"></span>
              <span *ngIf="_status.name=='Modified'"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.MODIFIED'"></span>
            </ng-option>
          </ng-select>
        </div>
        <div class="col-md-3 pl-0" *ngIf="user.role=='Super admin' || user.role=='Admin' || user.is_superuser">
          <ng-select (keydown.enter)="onSubmit()" tabindex="2" [multiple]="true" [items]="owner__email_items" bindLabel="name" bindValue="value"
            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate" name="owner__email"
            [(ngModel)]="owner__email" id="owner__email">
          </ng-select>
        </div>
        <div class="col-md-1 pl-0">
          <div class="input-group">
            <input (keydown.enter)="onSubmit()" tabindex="3" type="number" class="form-control" id="job-search"
              [placeholder]="'RESUME_PARSER.MANAGE_RESUME.ID'|translate" name="id"
              [(ngModel)]="id" />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <div class="input-group">
            <input (keydown.enter)="onSubmit()" tabindex="4" type="text" class="form-control" id="job-search"
              [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" name="file_name"
              [(ngModel)]="file_name" />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <ng-select (keydown.enter)="onSubmit()" tabindex="5" [multiple]="true" [items]="careersites"
            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_SOURCE'|translate" name="source" [(ngModel)]="source"
            id="source">
          </ng-select>
        </div>
        <div class="col-md-2 pl-0">
          <button tabindex="6" style="height: 38px;padding:6px" (click)="onSubmit()" class="btn fw-bold btn-primary"
            rippleEffect><span [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span> </button>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-center" *ngIf="isLoading; else no_loading" id="loading"
      style="margin: 50px auto">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ng-template #no_loading>
      <div class="row mb-1">
        <div class="d-flex col-md-4 col-12">
          <div class="d-flex justify-content-between align-items-center">
            <label class="d-flex align-items-center" style="max-width: 100%; flex-wrap: nowrap"><span
                [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
              <select class="form-control mx-25 pr-0 show-doc" [(ngModel)]="page.size">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
            </label>
          </div>
          <div class="d-flex justify-content-between align-items-center col-xxl-4 col-lg-6">
            
            <label class="d-flex align-items-center" style="flex-wrap: nowrap;max-width: 100px;">
              <input min = "1" max="page.totalElements" step="1" type="number" class="form-control" id="job-search"
              [placeholder]="'RESUME_PARSER.MANAGE_RESUME.PAGE'|translate" name="pageNumber"
              [(ngModel)]="pageNumber" (ngModelChange)="changePage($event)"/>
            <span class="mx-25" [translate]="'RESUME_PARSER.MANAGE_RESUME.PAGE'"></span>
          </label>
                
              
          </div>
        </div>
        <div class="col-md-8 col-12 d-flex justify-content-md-end">
          <div class="">
            <!-- <a routerLink="/parse-resume" class="btn btn-outline-primary mr-2" [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME'">
                    
                </a> -->
            <button *ngIf="!user.is_superuser" class="btn btn-primary btn-icon mr-2" rippleEffect
              (click)="modalOpenAD(modalAD)">
              <span [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME'" class="d-none d-lg-inline"></span>
              <span data-feather="upload" class="d-lg-none"> </span>
            </button>
            <ng-template #modalAD let-modal>
              <div class="modal-header" [@modalFadeAnimation]>
                <h4 class="modal-title" id="myModalLabel6" [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.TITLE'"></h4>
              </div>
              <div class="modal-body" tabindex="0" ngbAutofocus [@modalFadeAnimation]>
                <ngb-alert [type]="'success'" [dismissible]="false">
                  <div class="card" style="height: 70vh">
                    <div class="drag-area h-100">
                      <!-- <span class="visible"> Hiển thị ảnh bên dưới:</span> -->
                      <fieldset class="form-group h-100">
                        <!-- <label for="file-upload-single">Single:</label>
                            <div class="custom-file">
                              <input
                                type="file"
                                id="file"
                                name="file"
                                (change)="onFileChange($event)"
                              />
                            </div> -->

                        <label for="file-upload-multiple" class="w-100 h-100">
                          <div ng2FileDrop [ngClass]="{ 'file-drop-over': hasBaseDropZoneOver }"
                            (fileOver)="fileOverBase($event)" [uploader]="uploader" (onFileDrop)="setFile($event)"
                            class="text-center font-medium-5 text-uppercase file-drop-zone h-100 py-auto cursor-pointer"
                            [ngClass]="
                                  previews.length === 0
                                    ? 'd-flex align-items-center'
                                    : ''
                                ">
                            <div *ngIf="previews.length === 0" class="w-100">
                              <!-- <img src="assets/images/backgrounds/icon-floder.svg" alt=""
                                    style="object-fit: contain; width: 30%" /> -->
                              <i class="upload" data-feather="upload"></i>
                              <p class="mt-20" style="margin-top: 20px"
                                [translate]="'RESUME_PARSER.PARSE_RESUME.LABEL_UPLOAD'"></p>
                              <p style="font-size: 12px; opacity: 0.64"
                                [translate]="'RESUME_PARSER.PARSE_RESUME.FROM_DEVICE'"></p>
                            </div>
                            <div *ngIf="previews.length > 0"
                              class="row justify-content-start flex-wrap m-10 no-after p-2"
                              style="max-height: 100%; overflow-y: scroll">
                              <div *ngFor="let preview of previews; let i = index" class="">
                                <!-- <div *ngIf="preview.image;else noDoc" class="preview d-flex align-items-center justify-content-between text-lowercase mb-1 mx-1 px-1 rounded" style="background-color: lightblue; padding-top: 10px; padding-bottom: 10px">
                                <img [src]="preview.image" alt="name" style="margin-right: 5px;height: 39px;" />
                                <div class="d-flex flex-column align-item-start mw-100" style="width:100px;height: 125px;">
                                  <span class="text-truncate mw-100 font-medium-2" style="text-align: start">{{ preview.name }}</span>
                                  
                                </div>
                  
                                <button
                                  type="button"
                                  class="btn btn-icon btn-sm rounded-circle btn-white"
                                  rippleEffect
                                  (click)="removeImage(i, $event)"
                                >
                                  <span class="text-danger" data-feather="x"></span>
                                </button>
                              </div> -->
                                <div class="preview position-relative">


                                  <img class="position-relative" [src]="preview.image?preview.image:preview" width="100"
                                    [height]="preview.image?95:125" style="object-fit: contain; padding: 3px" />

                                  <button type="button"
                                    class="btn btn-sm position-absolute top-100 start-50 rounded-circle btn-danger"
                                    style="
                                        padding: '0.486rem';
                                        transform: translateX(-50%) translateY(-50%);
                                      " rippleEffect (click)="removeImage(i, $event)">
                                    <span data-feather="x"></span>
                                  </button>
                                  <div *ngIf="preview.name"><span
                                      class="d-inline-block text-truncate max-width-name-file w-100"
                                      [title]="preview.name" style="font-size: x-small;">{{ preview.name }}</span></div>
                                </div>
                              </div>
                            </div>
                            <!-- <div *ngIf="previews.length > 0"
                              class="d-flex align-items-center justify-content-start flex-wrap m-10 no-after"
                              style="max-height: 100%; overflow-y: scroll">
                              <div *ngFor="let preview of previews; let i = index">
                                <div class="preview position-relative">
                                  <div class="preview position-relative">


                                    <img class="position-relative" [src]="preview" width="100" height="125"
                                      style="object-fit: contain; padding: 3px" />

                                    <button type="button"
                                      class="btn btn-sm position-absolute top-100 start-50 rounded-circle btn-danger"
                                      style="
                                            padding: '0.486rem';
                                            transform: translateX(-50%) translateY(-50%);
                                          " rippleEffect (click)="removeImage(i, $event)">
                                      <span data-feather="x"></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                          <div class="custom-file" style="display: none">
                            <input type="file" class="custom-file-input" type="file" ng2FileSelect [uploader]="uploader"
                              id="file-upload-multiple" (onFileSelected)="setFile($event)"
                              accept="image/png, image/jpeg, application/pdf, .docx, .doc" multiple />
                          </div>
                        </label>
                      </fieldset>
                    </div>
                    <!-- IMAGE PREVIEW CONTAINER -->
                    <div class="container"></div>
                  </div>
                </ngb-alert>
              </div>
              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-outline-info" (click)="uploadWithSample()" *ngIf="canUploadWithSample"
                      rippleEffect>
                      <span translate="MODAL.UPLOAD_SAMPLE"></span>
                    </button> -->
                <div class="row col-12">
                  <ng-select class="col-4" [items]="careersites"
                    [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_SOURCE'|translate" name="selectedSource" [(ngModel)]="selectedSource"
                    id="source">
                  </ng-select>
                  
                    
                    <ng-select class="col-4" id="JR"
                    [placeholder]="'RESUME_PARSER.DETAIL_RESUME.PLACEHOLDER.PLACEHOLDER_SELECT_MATCHING'|translate"
                    name="selectedJr" [(ngModel)]="selectedJr">
                    <ng-option *ngFor="let jr of items_jr" [value]="jr.jobReqId">
                      <span [title]="jr.jobTitle">{{ jr.jobTitle }}</span>
                    </ng-option>

                </ng-select>
                <ng-select class="col-4" [placeholder]="'RESUME_PARSER.TALENT_POOL.FOLDER'|translate" [searchable]="false" [items]="talentPools" bindLabel="name" bindValue="id"
                  [(ngModel)]="selectedTalentpool">
                  </ng-select>
                </div>
                
                <div class="row">
                  <div class="d-flex align-items-center" *ngIf="files?.length>50">
                    <small class="text-danger mr-1"><span data-feather="alert-triangle"> </span> <span [translate]="'RESUME_PARSER.MANAGE_RESUME.LIMIT_UPLOAD_FILE'"></span></small>
                  </div>
                  
                  <button class="btn btn-primary mr-1" (click)="ParseListResume()" (click)="modal.close('Accept click')"
                  [disabled]="!files || files.length == 0 || files.length>50 ? true : false" rippleEffect>
                  <span [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.IMPORT'"></span> <span> {{previews.length}}
                    CV</span>
                </button>
                <button type="button" class="btn btn-outline-danger" (click)="modal.close('Accept click')"
                  (click)="setFile(null)" rippleEffect>
                  <span [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></span>
                </button>
                </div>
              </div>
            </ng-template>
            <button *ngIf="!user.is_superuser" class="btn fw-bold btn-primary mr-2" (click)="syncEmail()"
              [translate]="'RESUME_PARSER.PARSE_RESUME.SYNC_EMAIL'"></button>
          </div>
          <div *ngIf="resume_parser.length>0">
            <button *ngIf="tag_export;else noLoading" class="btn btn-success btn-icon mr-2 place-order" disabled>
              <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              <span [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"></span>
            </button>
            <ng-template #noLoading>
              <button class="btn btn-success" [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"
                (click)="modalOpenDownload(modalDownload)">

              </button>
            </ng-template>
            <ng-template #modalDownload let-modal>
              <form [formGroup]="downloadDocumentForm">
                <div class="modal-header">
                  <h4 class="modal-title" [translate]="'RESUME_PARSER.MANAGE_RESUME.OPTION'">

                  </h4>
                </div>
                <div class="modal-body" ngbAutofocus style="overflow-y: visible;">
                  <div class="d-flex">
                    <div class="form-group col-md-6">
                      <label for="file_name"><span [translate]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'"></span><b
                          style="color:red">*</b></label>
                      <input tabindex="1" type="text" id="file_name" formControlName="file_name" class="form-control"
                        name="file_name" required [ngClass]="{
                                  'is-invalid': f.file_name.errors && !f.file_name.valid && f.file_name.touched
                                }" />
                      <div *ngIf="f.file_name.errors && !f.file_name.valid && f.file_name.touched"
                        class="invalid-feedback">
                        <div *ngIf="f.file_name.errors.required" [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">

                        </div>

                      </div>

                    </div>
                    <div class="form-group col-md-6">
                      <label for="type" [translate]="'RESUME_PARSER.MANAGE_RESUME.TYPE'"></label><b
                        style="color:red">*</b>
                      <ng-select tabindex="2" style="height: 2.714rem;" id="type" (change)="changeType($event)"
                        formControlName="type" required [ngClass]="{
                          'is-invalid': f.type.errors && !f.type.valid && f.type.touched
                        }">

                        <ng-option *ngFor="let type of types" [value]="type.value">
                          {{ type.name }}
                        </ng-option>
                      </ng-select>
                      <div *ngIf="f.type.errors && !f.type.valid && f.type.touched" class="invalid-feedback">
                        <div *ngIf="f.type.errors.required" [translate]="'RESUME_PARSER.MANAGE_RESUME.REQUIRED'">

                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="form-group col-md-6">
                      <label for="from"><span
                          [translate]="'RESUME_PARSER.DETAIL_RESUME.START_TIME_CREATE'"></span></label>
                      <input tabindex="3" autocomplete="off" formControlName="from" bsDatepicker type="text"
                        class="form-control" id="from" name="from"
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                        [placeholder]="'RESUME_PARSER.DETAIL_RESUME.START_TIME'|translate" />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="to"><span [translate]="'RESUME_PARSER.DETAIL_RESUME.END_TIME_CREATE'"></span></label>
                      <input tabindex="4" autocomplete="off" formControlName="to" bsDatepicker type="text"
                        class="form-control" id="to" name="to"
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                        [placeholder]="'RESUME_PARSER.DETAIL_RESUME.END_TIME'|translate" />
                      <!-- <div *ngIf="downloadDocumentForm.errors?.dateRangeInvalid && f.to.touched" class="invalid-feedback">
                          <div [translate]="'RESUME_PARSER.MANAGE_RESUME.INVALID_DATE_RANGE'">Invalid date range</div>
                        </div> -->
                      <div *ngIf="downloadDocumentForm.hasError('dateRangeInvalid') && f.type.valid">
                        <small class="form-text text-danger"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.INVALID_DATE_RANGE'"></small>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="form-group col-md-6">
                      <label for="status" [translate]="'RESUME_PARSER.PARSE_RESUME.STATUS'"></label>
                      <ng-select tabindex="5" id="status" formControlName="status">

                        <ng-option *ngFor="let _statusDownload of statusDownload" [value]="_statusDownload.value">
                          <span *ngIf="language=='en';else VN">{{ _statusDownload.name }}</span>
                          <ng-template #VN>
                            <span>{{ _statusDownload.name_vn }}</span>
                          </ng-template>
                        </ng-option>
                      </ng-select>

                    </div>
                    <div class="form-group col-md-6" *ngIf="user.is_superuser">
                      <label for="company" [translate]="'RESUME_PARSER.DETAIL_RESUME.COMPANY'"></label>
                      <!-- <ng-select tabindex="6" id="company" formControlName="company" multiple="true"
                        [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate">

                        <ng-option *ngFor="let _owner__email_items of owner__email_items"
                          [value]="_owner__email_items.id">
                          <span *ngIf="language=='en' && _owner__email_items.name=='Tất cả'">All</span>
                          <span *ngIf="language=='vn' && _owner__email_items.name=='Tất cả'">Tất cả</span>
                          <span *ngIf="_owner__email_items.name!=='Tất cả'">{{ _owner__email_items.name }}</span>
                          
                        </ng-option>
                      </ng-select> -->
                      <select style="height: 2.714rem;" id="company" class="form-control"
                            formControlName="company" (change)="changeCompany($event)">
                            <option value="" [translate]="'DASHBOARD.ALL_COMPANY'"></option>
                            <option *ngFor="let company of companys" [value]="company.id">
                                {{ company.name }}
                            </option>
                        </select>

                    </div>
                    <div class="form-group col-md-6" *ngIf="user.is_site_admin ||user.is_super_site_admin">
                      <label for="owner" [translate]="'RESUME_PARSER.MANAGE_RESUME.OWNER'"></label>
                      <ng-select tabindex="6" id="owner" formControlName="owner" multiple="true"
                        [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate">

                        <ng-option *ngFor="let _owner__email_items of owner__email_items"
                          [value]="_owner__email_items.id">
                          <span *ngIf="language=='en' && _owner__email_items.name=='Tất cả'">All</span>
                          <span *ngIf="language=='vn' && _owner__email_items.name=='Tất cả'">Tất cả</span>
                          <span *ngIf="_owner__email_items.name!=='Tất cả'">{{ _owner__email_items.name }}</span>
                          
                        </ng-option>
                      </ng-select>

                    </div>
                    
                    
                  </div>
                  <div>
                    <div class="form-group col-md-6" *ngIf="user.is_superuser">
                      <label for="owner" [translate]="'RESUME_PARSER.MANAGE_RESUME.OWNER'"></label>
                      <ng-select tabindex="6" id="owner" [readonly]="downloadDocumentForm.get('company').value==''" formControlName="owner"
                        [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate" multiple="true">

                        <ng-option *ngFor="let _owner__email_items of owner__email_company_items"
                          [value]="_owner__email_items.id">
                          <span *ngIf="language=='en' && _owner__email_items.name=='Tất cả'">All</span>
                          <span *ngIf="language=='vn' && _owner__email_items.name=='Tất cả'">Tất cả</span>
                          <span *ngIf="_owner__email_items.name!=='Tất cả'">{{ _owner__email_items.name }}</span>
                          
                        </ng-option>
                      </ng-select>

                    </div>
                  </div>

                </div>
                <div class="modal-footer">

                  <button tabindex="7" [disabled]="!downloadDocumentForm.valid" (click)="downloadDocument()"
                    (click)="modal.close('Accept click')" class="btn btn-primary mr-1"
                    [translate]="'RESUME_PARSER.MANAGE_RESUME.ACCEPT'">Submit</button>
                  <button (click)="modal.dismiss('Cross click')" type="button" (click)="cancel()" rippleEffect
                    class="btn btn-secondary" [translate]="'RESUME_PARSER.MANAGE_RESUME.CANCEL'">Cancel</button>
                </div>
              </form>
            </ng-template>
            <!-- <div ngbDropdown>
                  <button
                    ngbDropdownToggle
                    class="btn btn-success"
                    type="button"
                    id="dropdownMenuButton"
                    rippleEffect
                    [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"
                    (click)="modalOpenDownload(modalDownload)"
                    >
                    
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                    <a ngbDropdownItem (click)="export_csv()">Csv</a>
                    <a ngbDropdownItem (click)="export_excel()">Excel</a>
                  </div>
                </div> -->
          </div>
          <!-- <div class="alert alert-success alert-dismissible fade show me-2 mt-4" role="alert" *ngIf="tag_export">
            <strong [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOADING'"></strong>
          </div> -->
        </div>
      </div>
      <ngx-datatable id="table-user" #tableRowDetails class="bootstrap core-bootstrap" [rows]="resume_parser"
        [externalPaging]="true" [selectionType]="SelectionType.checkbox" (select)="onSelect($event)"
        [selected]="selectedRows" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size"
        (page)="setPage($event)" [rowHeight]="50" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
        [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
          [resizeable]="false">
          <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)" id="headerFolderChkbxRef" />
              <label class="custom-control-label" for="headerFolderChkbxRef"></label>
            </div>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value"
            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input"
                [checked]="setIsSelectedRows(row)"
                (change)="onCheckboxChangeFn($event)" id="rowFolderChkbxRef{{ rowIndex }}"
              />
              <label class="custom-control-label" for="rowFolderChkbxRef{{ rowIndex }}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" [width]="widthColumn" prop="file_name">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <a *ngIf="row.status!='Processing'&&row.status!='Wait_to_process';else noLink"
                routerLink="/manage-resume/detail-resume/{{row.id}}" data-bs-toggle="tooltip" data-bs-placement="bottom"
                title="{{row.file_name}}" class="d-inline-block text-truncate max-width-custom w-100"
                style="max-width: 200px;">{{ row.file_name
                }}</a>
              <ng-template #noLink>
                <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.file_name}}"
                  class="d-inline-block text-truncate max-width-custom w-100">{{ row.file_name }}</span>
              </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate" prop="status"
          [width]="widthColumn*2/3">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom"
                title="{{row.message}}">
                <span *ngIf="row.status.replaceAll('_',' ')=='Approved'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED'"></span>
                  <span *ngIf="row.status.replaceAll('_',' ')=='Duplicated'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DUPLICATED'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Done'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DONE'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Failure'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.FAILURE'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Processing'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.PROCESSING'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Wait to process'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.WAIT_TO_PROCESS'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Suspended'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.SUSPENDED'"></span>
                <span *ngIf="row.status.replaceAll('_',' ')=='Modified'"
                  [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.MODIFIED'"></span>

              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate"
            prop="sf4c_status"
            [width]="widthColumn"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
              <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.sf4c_message}}" >{{row.sf4c_status.replaceAll("_"," ")}}</span>
            </div>
          </ng-template>
          </ngx-datatable-column> -->
        <!-- <ngx-datatable-column
            [name]="'RESUME_PARSER.MANAGE_RESUME.JOB_APPLICATION_STATUS'|translate"
            prop="status"
            [width]="widthColumn"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
                <span *ngIf="row.status=='SF4C_uploaded';else noStatus" class="badge bg-{{job_status_color[row.job_application_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.job_application_detail_status}}" >{{row.job_application_status}}</span>
                <ng-template #noStatus></ng-template>
            </div>
          </ng-template>
          </ngx-datatable-column> -->
        <!-- <ngx-datatable-column
            [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate"
            [width]="widthColumn"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <span *ngIf="row.conf_score>range_conf.success" class="badge bg-success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                <span *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success" class="badge bg-warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                <span *ngIf="row.conf_score<range_conf.warning" class="badge bg-danger">{{row.conf_score*100|number:'1.2-2'}}%</span>
            </div>
          </ng-template>
          </ngx-datatable-column> -->


        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn" prop="current_title">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span class="d-inline-block text-truncate max-width-custom w-100" style="max-width: 200px;"
                data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title
                }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.SOURCE'|translate" [width]="widthColumn" prop="source">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="d-inline-block text-truncate max-width-custom w-100">
              <span>{{ row.source }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.DETAIL_RESUME.SHARE_BY'|translate" [width]="widthColumn" prop="share_by">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.share_by }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CREATE_AT'|translate" [width]="widthColumn*2/3" prop="create_at">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.created_at|date:'d/M/yyyy, h:mm a' }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'|translate" [width]="widthColumn*2/3" prop="modified_at">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.modified_at|date:'d/M/yyyy, h:mm a' }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate" [width]="widthColumn"
          *ngIf="user.role=='Super user' || user.role=='Admin'||user.role=='Super admin'" prop="owner">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
              <span>{{ row.owner }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <!-- ACTIONSSSSSSSSSSSSSSSSS -->
        <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FIRST_NAME'|translate" prop="first_name" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.LAST_NAME'|translate" prop="last_name" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate" prop="email" width="200">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number" width="140">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.GENDER'|translate" prop="gender" width="100">
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.DATE_OF_BIRTH'|translate" width="200">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span>{{ row.date_of_birth|date:'longDate' }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" [width]="widthColumn">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{ row.current_title }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="w-100">
                <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column> -->

        <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.ACTION' | translate" [sortable]="false"
          [sortable]="false" [width]="widthColumn*2/3" [draggable]="false" *ngIf="!user.is_superuser">
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="d-flex align-items-center">
              <a type="button" href="javascript:void(0)" routerLink="/manage-resume/edit-resume/{{row.id}}" title="Sửa"
                class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                <span class="d-flex align-items-center justify-content-center"><i class="fa fa-pencil-square-o"></i>
                </span>
              </a>
              <a [ngStyle]="{'pointer-events':row.status=='Processing'||row.sf4c_status=='SF4C_uploading'||row.sf4c_status=='SF4C_wait_to_upload'?'none':'auto'}"
                type="button" href="javascript:void(0)" (click)="deleteResume(row.id)" title="Xóa"
                class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                <span class="d-flex align-items-center justify-content-center"><i class="fa fa-trash-o"></i>
                </span>
              </a>
              <!-- <a href="javascript:void(0)" routerLink="/manage-resume/edit-resume/{{row.id}}" title="Edit">
                        <div class="  btn btn-flat-primary mr-1">
                          <div class="">
                            <span
                              class="d-flex align-items-center justify-content-center"
                              ><i class="fa fa-pencil-square-o"></i>
                            </span>
                          </div>
                        </div>
                      </a>
                      <a href="javascript:void(0)" (click)="deleteResume(row.id)" title="Delete">
                        <div class="  btn btn-flat-danger mr-1">
                          <div class="">
                            <span
                              class="d-flex align-items-center justify-content-center"
                              ><i class="fa fa-trash-o"></i>
                            </span>
                          </div>
                        </div>
                      </a> -->
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-template>
  </div>
</div>
<div class="popup" [@slideFade]="showActionsPopup ? 'visible' : 'hidden'">
  <div class="button-container" *ngIf="showActionsPopup">
    <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0" (click)="unselectAllRows()">
      <span data-feather="x"></span>
    </button>

    <div>
      <div>
        <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
          id="dropdownMenuButton" rippleEffect (click)="modalOpenAddResume(modalAddResume)"
          [disabled]="!getFilterSelectedRows()">
          <div style="margin: 0 auto 5px auto">
            <span data-feather="upload"></span>
          </div>
          <span [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME_TO_TALENTPOOL'"></span>
        </button>
      </div>
      <ng-template #modalAddResume let-modal>
        <ng-modal class="modalAddResume">
          <modal-header class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUME_TO_TALENTPOOL'">
              Add resume to Talent pool
            </h4>
          </modal-header>
          <modal-content>
            <form class="form form-vertical" [formGroup]="addResumeToTalentPoolForm">
              <div class="modal-body flex-grow-1" tabindex="1" style="overflow-y: visible;">
                <div class="form-group">
                  <label class="form-label" for="basic-icon-default-fullname"><span
                      [translate]="'RESUME_PARSER.TALENT_POOL.NAME_FOLDER'">Name talent pool</span><span
                      style="color: red; padding-left: 5px">*</span></label>
                  <ng-select [searchable]="false" [items]="talentPools" bindLabel="name" bindValue="id"
                    formControlName="talent_pool">
                  </ng-select>
                  <span *ngIf="
                  addResumeToTalentPoolForm.controls.talent_pool.invalid &&
                  addResumeToTalentPoolForm.controls.talent_pool.touched
                " class="invalid-form">
                    <small class="form-text text-danger"
                      *ngIf="addResumeToTalentPoolForm.controls.talent_pool.errors.required"
                      [translate]="'RESUME_PARSER.TALENT_POOL.REQUIRED'"></small>
                  </span>


                </div>

                <button tabindex="2" (click)="onAddResumeToTalentPool()" [disabled]="!addResumeToTalentPoolForm.valid"
                  class="btn btn-primary mr-1">
                  <span [translate]="'RESUME_PARSER.MANAGE_RESUME.ADD_RESUMES'">Add resume to talent pool</span>
                </button>
                <button type="reset" class="btn btn-outline-secondary" (click)="_modalService.dismissAll()"
                  rippleEffect>
                  <span [translate]="'RESUME_PARSER.TALENT_POOL.CANCEL'">Cancel</span>
                </button>
              </div>
            </form>
          </modal-content>
        </ng-modal>
      </ng-template>
    </div>
    <div>
      <div>
        <button class="btn btn-success d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
          id="dropdownMenuButton" rippleEffect (click)="approveMultiResume()" [disabled]="!getFilterSelectedRows()">
          <div style="margin: 0 auto 5px auto">
            <span data-feather="check"></span>
          </div>
          <span [translate]="'RESUME_PARSER.MANAGE_RESUME.TOAST.APPROVE_MUL_RESUME'">Remove resumes</span>
        </button>
      </div>
    </div>
    <div>
      <div>
        <button class="btn btn-danger d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
          id="dropdownMenuButton" rippleEffect (click)="removeResumes()">
          <div style="margin: 0 auto 5px auto">
            <span data-feather="trash"></span>
          </div>
          <span [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_RESUMES'">Remove resumes</span>
        </button>
      </div>
    </div>
    
  </div>
</div>